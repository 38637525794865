@keyframes spinning-circle {
    0% {
      stroke-dashoffset: 0;
      stroke-dasharray: 150.6 100.4;
    }
    50% {
      stroke-dasharray: 1 250;
    }
    100% {
      stroke-dashoffset: 502;
      stroke-dasharray: 150.6 100.4;
    }
  }
  
.spinner {
    animation: 2s linear spinning-circle infinite;
    align-self: center;
    justify-self: center;
    padding: 100px 100px;
}

@font-face {
    font-family: 'Impacto';
    font-style: normal;
    font-weight: 400;    
    src: url('../fonts/impact.woff2') format('woff2');
}

canvas {
    font-family: 'Impacto';
}