main {
    padding: 36px;
    display: flex;
    flex-direction: column;
    gap: 10px;
}

form {
    display: flex;
    flex-direction: column;
    gap: 5px;
}

input {
    border-radius: 5px;
    border: 1px solid #D5D4D8;
    height: 32px;
    padding-left: 10px;
}

button{
    grid-column: 1 / -1;
    background: linear-gradient(90.41deg, #1b0691 1.14%, #3b02da 100%);   
    border-radius: 5px;
    color: white;
    border-style: none;
    font-weight: 700;
    font-size: 16px;
    line-height: 19px;
    height: 36px;
    text-align: center;
    letter-spacing: -0.07em;
    margin-top: 15px; 
    cursor: pointer;
}



button:hover{
    background: linear-gradient(90.41deg, #140085 1.14%, #3000b6 100%);   
}

button:active{
    background: linear-gradient(90.41deg, #1b0691 1.14%, #3b02da 100%);    
    box-shadow: 3px 4px rgba(102, 102, 102, 0.068);
    transform: translateY(3px);
}

/* .meme {
    position: relative;
    display: flex;
    flex-direction: column;
} */

/* .meme{
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
} */
.meme, .save-img-btn-container{
    display: flex;
    flex-direction: column;

}

.save-img-btn-container.nostyle:link {
    text-decoration: inherit;
    color: inherit;
    cursor: auto;
}

.save-img-btn-container.nostyle:visited {
    text-decoration: inherit;
    color: inherit;
    cursor: auto;
}

canvas{
    border: 2px solid #f3f3f3;
    border-radius: 5px;
}

.meme-image {
    border: 2px solid #f3f3f3;
    border-radius: 5px;
    max-width: 490px; 
    height:auto;
}

.meme-text {
    position: absolute;
    width: 80%;
    text-align: center;
    left: 50%;
    transform: translateX(-50%);
    margin: 15px 0;
    padding: 0 5px;
    font-family: impact, sans-serif;
    font-size: 2em;
    text-transform: uppercase;
    color: white;
    letter-spacing: 1px;
    text-shadow:
        2px 2px 0 #000,
        -2px -2px 0 #000,
        2px -2px 0 #000,
        -2px 2px 0 #000,
        0 2px 0 #000,
        2px 0 0 #000,
        0 -2px 0 #000,
        -2px 0 0 #000,
        2px 2px 5px #000;
}

.top {
    top: 0;
}

.bottom {
    bottom: 0;
}
@media only screen and (min-width: 600px) {
    .meme-image{
        align-self: center;
    }
}
@media only screen and (min-width: 768px) {
    form {
        display: grid;
        grid-template: 2rem 2rem / 1fr 1fr;
        gap:17px;
    }
    .meme-image{
        place-self: center;
    }
    form img {
        margin-top: 10px;
        width: 500px;
        height: auto;
    }
    button{
        width: 688px;
        place-self: center;
    }
}