.images-carousel {
    background: linear-gradient(90.41deg, #1b0691 1.14%, #3b02da 100%);   
    gap: 10px;
    padding: 15px;
    scroll-snap-type: x mandatory;
    overflow-x: scroll;
    display: flex;
    /* margin-top: 10px; */
}


@media only screen and (min-width: 768px) {
    .images-carousel {
        margin-top: 20px;
    }
}
.images {
    height: 5rem;
    object-fit: contain;
    border: 2px solid rgba(128, 128, 128, 0.116);
    scroll-snap-align: center;
    cursor: pointer;
    position: relative;
    /* height: 10rem; */
}

  