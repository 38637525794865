header {
    display: flex;
    font-family: 'Karla', sans-serif;
    align-items: center;
    padding-left: 20px;
    height: 65px;
    background: linear-gradient(90deg, #301f8d 1.18%, #4c18da 100%);
    color: white;
}

header h3 {
    font-weight: 700;
    font-size: 1.25rem;
    line-height: 24px;
    letter-spacing: -0.1em;
}

header h5 {
    font-weight: 500;
    font-size: 0.75rem;
    line-height: 15px;
    margin-left: auto;
    margin-right: 7px;
}

header img {
    width: 32px;
    height: 26px;
    margin-right: 7px;
}